import React from 'react';
import Section from '@nutkit/component-section';
import Panel from '@nutkit/component-panel';
import { Container } from '@nutkit/component-grid';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Heading, Text } from '@nutkit/component-text';
import Align from '@nutkit/component-align';

import styles from './RegisterEmailBanner.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerEmail.banner';

const RegisterEmailBanner = () => {
  const { t } = useTranslation();
  return (
    <Section className={styles.container}>
      <Container className={styles['banner-container']}>
        <Align alignItems="center" justifyContent="center" fullHeight>
          <Panel
            header={
              <>
                <header className={styles.header}><Heading className={styles.heading}>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading></header>
                <hr className={styles.divider} />
              </>
            }
            isFullWidth={false}
            footer={<footer className={styles.footer}>J.P. Morgan | Nutmeg</footer>}
            space='inset--lg'
          >
            <Text>{t(`${TRANSLATION_NAMESPACE}.content`)}</Text>
            <Text>{t(`${TRANSLATION_NAMESPACE}.disclaimer`)}</Text>
          </Panel>
        </Align>
      </Container>
    </Section>
  );
};

export default RegisterEmailBanner;