import React, { ReactNode } from 'react';
import { Container, Row, Col, gutters } from '@nutkit/component-grid';

import styles from './HalfLayout.module.scss';

type sizeProps = {
  sm?: {
    size?: number,
    offset?: number
  };
  md?: {
    size?: number,
    offset?: number
  };
  lg?: {
    size?: number,
    offset?: number
  };
  xl?: {
    size?: number,
    offset?: number
  };
}

type HalfLayoutProps = {
  left: ReactNode;
  right: ReactNode;
  leftLayoutSizes?: sizeProps;
  rightLayoutSizes?: sizeProps;
  className?: string;
}

const defaultLayoutValues = {
  sm: {
    size: 10,
    offset: 1
  },
  md: {
    size: 6,
    offset: 0
  },
  lg: {
    size: 6,
    offset: 0
  },
  xl: {
    size: 6,
    offset: 0
  }
};

const HalfLayout = ({
  left,
  right,
  leftLayoutSizes = defaultLayoutValues,
  rightLayoutSizes = defaultLayoutValues,
  className
}: HalfLayoutProps) => {
  return (
    <Container className={`${styles.container} ${className}`} fluid>
      <Row gutters={gutters.SM}>
        <Col sm={leftLayoutSizes.sm} md={leftLayoutSizes.md} lg={leftLayoutSizes.lg} xl={leftLayoutSizes.xl}>
          {left}
        </Col>
        <Col sm={rightLayoutSizes.sm} md={rightLayoutSizes.md} lg={rightLayoutSizes.lg} xl={rightLayoutSizes.xl}>
          {right}
        </Col>
      </Row>
    </Container>
  );
};

export default HalfLayout;
